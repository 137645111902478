import _ from 'underscore';

const $searcher = $('#js-searcher');
const inputSearch = '.js-input-search';
const searchClear = '.js-search-clear';
const results = '#js-searcher-results';
const searchTrigger = '.js-custom-search-trigger';

(function ($) {
    /**
     * Event listeners
     */
    $(document).on(
        'input',
        inputSearch,
        _.debounce(function (e) {
            e.preventDefault();
            let s = $(this).val();

            // Expand searcher
            focus();

            // Run search if value is not too short
            if (s.length >= 3) {
                setStates(['filled']);
                runSearchDebounced(s);
            } else if (s.length > 0) {
                // setStates(['tooShort']);
                shortSearch();
            } else {
                emptySearch();
            }
        }, 300)
    );

    // Show or hide hints on click
    $(document).on('click', inputSearch, function (e) {
        e.stopPropagation();
        focus();
    });

    $(document).on('click', function (e) {
        if (!$searcher.is(e.target) && $searcher.has(e.target).length === 0) {
            collapse();
        }
    });

    // Clear search on clear button click
    $(document).on('click', searchClear, function (e) {
        e.preventDefault();
        $(inputSearch).val('').trigger('input');
        $('#js-searcher-results').removeClass('is-active');

        $searcher.removeClass('-expanded -focused -empty');
        $('.dropdown-search__container').removeClass('is-active');
        $('.js-search-clear').removeClass('is-active');
    });

    // Trigger search on triggers
    $(document).on('click', searchTrigger, function (e) {
        $(inputSearch).closest('form').trigger('submit');
    });

    /**s
     * Add methods to element
     */
    if ($(inputSearch).length > 0) {
        $(inputSearch)[0].searcherFocus = focus;
        $(inputSearch)[0].searcherCollapse = collapse;
    }

    // Terms search
    $(document).on(
        'input',
        '.js-filter-terms-search',
        _.debounce(function (e) {
            e.preventDefault();
            let s = $(this).val();
            let taxonomy = $(this).attr('data-taxonomy');
            let filterName = $(this).attr('data-filter-name');

            $.ajax({
                url: ajax.url,
                data: {
                    action: 'mda_terms_search',
                    taxonomy: taxonomy,
                    s: s,
                    filterName: filterName,
                },
                type: 'post',
                beforeSend: function () {
                    $(`[data-taxonomy-terms=${taxonomy}]`).html('');
                },
                success: function (response) {
                    $(`[data-taxonomy-terms=${taxonomy}]`).html(response);
                },
            });
        }, 300)
    );
})(jQuery);

/**
 * Methods
 */

// Run search and debounced instance
let runSearchDebounced = _.debounce(runSearch, 500);
function runSearch(value) {
    $.ajax({
        url: ajax.url,
        data: {
            action: 'mda_search_hints',
            s: value,
        },
        type: 'post',

        beforeSend: function () {
            setStates(['loading']);
        },
        success: function (response) {
            // Do not replace html if current value is too short
            if ($(inputSearch).val().length < 3) {
                return;
            }

            // Set searcher state
            if (response.state === 'hasResults') {
                setStates(['hasResults']);
            } else {
                setStates(['noResults']);
            }

            // Replace html in search box
            if (response.html) {
                $(results).html(response.html);
            }
        },
    });
}

// Render empty search template
function emptySearch() {
    $.ajax({
        url: ajax.url,
        data: {
            action: 'mda_empty_search',
        },
        type: 'post',
        beforeSend: function () {
            setStates(['loading']);
        },
        success: function (response) {
            setStates(['empty']);
            // Replace html in search box
            if (response.html) {
                $(results).html(response.html);
            }
        },
    });
}

// Render to short search template
function shortSearch() {
    $.ajax({
        url: ajax.url,
        data: {
            action: 'mda_short_search',
        },
        type: 'post',
        beforeSend: function () {
            setStates(['loading']);
        },
        success: function (response) {
            setStates(['tooShort']);
            // Replace html in search box
            if (response.html) {
                $(results).html(response.html);
            }
        },
    });
}

function focus() {
    setStates(['expanded', 'focused']);
}

function collapse() {
    setStates(['collapsed']);
}

/**
 * Searcher state management
 */

const searcherStatesElements = [$searcher];
const searcherStates = {
    loading: {
        add: '-loading',
    },

    focused: {
        add: '-focused',
    },

    collapsed: {
        add: '-collapsed',
        remove: '-expanded -focused',
    },

    expanded: {
        add: '-expanded',
        remove: '-collapsed',
    },

    empty: {
        add: '-empty',
        remove: '-too-short -has-results -no-results -loading -filled',
    },

    tooShort: {
        add: '-too-short',
        remove: '-empty -has-results -no-results -loading -filled',
    },

    filled: {
        add: '-filled',
        remove: '-empty -tooShort',
    },

    hasResults: {
        add: '-has-results',
        remove: '-no-results -empty -too-short -loading',
    },

    noResults: {
        add: '-no-results',
        remove: '-has-results -empty -too-short -loading',
    },
};

function setStates(states = []) {
    // Get state class and return if not exists
    const choosenStates = states
        .map(state => searcherStates[state])
        .filter(state => state !== undefined);
    if (choosenStates.length === 0) {
        console.warn(
            `Some statuses is not available in searcher state list: `,
            searcherStates
        );
        return;
    }

    // Choose classes to add and to remove
    const currentClasses = choosenStates.map(state => state.add);
    const prevClasses = choosenStates.map(state => state.remove);

    // Add state class
    searcherStatesElements.forEach(el => {
        $(el).addClass(currentClasses.join(' '));
        $(el).removeClass(prevClasses.join(' '));
    });
}
